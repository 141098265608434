"use client";
import React, { useState, useEffect } from "react";
import SparkleBlue from "@/assets/svgs/SparkleBlue.svg";
import Image from "next/image";

const NotificationBanner = () => {
    const [showBanner, setShowBanner] = useState(true);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    const messages = [
        {
            text: "Instagram Direct Connection is Live!",
            link: "https://app.postly.ai/",
        },
        {
            text: "Bluesky Publishing is Live!",
            link: "https://app.postly.ai/",
        },
        
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 20000); // Change message every 20 seconds

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, [messages.length]);

    return (
        <div className={`w-full ${showBanner ? "block" : "hidden"} bg-postlyLightGreen py-3 px-6 rounded-lg shadow-lg`}>
            {messages[currentMessageIndex] && (
                <div className="flex flex-col md:flex-row items-center justify-center flex-wrap text-gray-600 animate-fadeIn">
                    <span className="text-md font-normal mr-3 flex items-center">
                        <Image 
                            src={SparkleBlue} 
                            alt="Sparkle" 
                            className="w-8 mr-1" 
                        />
                        What&apos;s new:
                    </span>
                    <div className="flex items-center space-x-3 flex-wrap">
                        <span className="text-md font-normal text-gray-600">
                            {messages[currentMessageIndex].text}
                        </span>
                        <a
                            href={messages[currentMessageIndex].link}
                            className="inline-block text-md text-gray-600 bg-white py-2 px-4 rounded-full font-normal hover:bg-postlyDarkBlue hover:text-white transition-colors duration-300"
                        >
                            Get started now
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationBanner;
